input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
    margin: 0;
}

.code-input input{
   text-align: center;
   padding: 1rem;
   font-family: 'Montserrat';
   border-radius: 12px;
   border: 1px solid #72655133;
   box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;
   margin: 4px;
   padding-left: 8px;
   width: 50px;
   height: 55px;
   font-size: 23px;
   font-weight: 100;
   box-sizing: border-box;
   color: black;
   background-color: white;
}

.code-input input:hover {
   border: 1px solid #FFB800;
 }

 .code-input input:focus{
   outline: none;
   border: 1px solid #FFB800;
 }

.code-input input::placeholder {
   color: #999;
 }

.react-code-input{
   border: 0px solid red;
}
